// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portfolio-mlv-primary: mat.define-palette(mat.$indigo-palette);
$portfolio-mlv-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$portfolio-mlv-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portfolio-mlv-theme: mat.define-light-theme((color: (primary: $portfolio-mlv-primary,
        accent: $portfolio-mlv-accent,
        warn: $portfolio-mlv-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($portfolio-mlv-theme);

// Importing Theme's Base Scss Library
@import "scss-config";

* {
  box-sizing: border-box;
}

html,
body {
  // background-color: var(--bgDepth); // To create a mobile app feeling with the content with a border radius.
  background-color: var(--bgSurface);
  // font-family: var(--tfBody);
  height: 100%;
}

/*------------------------------------*\
        $COMMON UTILITY CLASSES
\*------------------------------------*/

* {
  transition: all 0.4s ease-in-out;

  @font-face {
    font-family: Essential Sans;
    src: url("./assets/fonts/Jen-Wagner-Essential-Sans-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: Essential Sans;
    font-weight: bold;
    src: url("./assets/fonts/Jen-Wagner-Essential-Sans-Bold.otf") format("opentype");
  }

  @font-face {
    font-family: Essential Sans;
    font-weight: medium;
    src: url("./assets/fonts/Jen-Wagner-Essential-Sans-Medium.otf") format("opentype");
  }

  @font-face {
    font-family: Essential Sans;
    font-weight: 500;
    src: url("./assets/fonts/Jen-Wagner-Essential-Sans-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: Euclid Circular B;
    src: url("./assets/fonts/Euclid Circular B Medium.ttf") format("opentype");
  }

  @font-face {
    font-family: Euclid Circular B;
    font-weight: 500;
    src: url("./assets/fonts/Euclid Circular B Medium.ttf") format("opentype");
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--txInverted);
    font-family: var(--tfHeaders);
    font-weight: 500;
    letter-spacing: -1px;
    margin: 0;
    padding: 0;
  }

  p {
    color: var(--txInverted);
    font-family: var(--tfBody);
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  // ELEMENT BORDER RADIUS
  &.brXS {
    border-radius: var(--buXS);
  }

  &.brSM {
    border-radius: var(--buSM);
  }

  &.brMD {
    border-radius: var(--buMD);
  }

  &.brLG {
    border-radius: var(--buLG);
  }

  // ELEMENT HEIGHT
  &.hgt-content {
    height: fit-content;
  }

  &.hgt-full {
    display: flex;
    height: 100%;
  }

  &.hgt-min-lg {
    height: 704px;
  }

  // ELEMENT MARGINS
  &.no-mrg {
    margin: 0 !important;
  }

  &.mrg-t-xl {
    margin-top: 40px !important;
  }

  &.mrg-t-lg {
    margin-top: 32px !important;
  }

  &.mrg-t-md {
    margin-top: 24px !important;
  }

  &.mrg-t-sm {
    margin-top: 16px !important;
  }

  &.mrg-t-xs {
    margin-top: 8px !important;
  }

  &.mrg-t-xxs {
    margin-top: 4px !important;
  }

  &.mrg-r-lg {
    margin-right: 32px !important;
  }

  &.mrg-r-md {
    margin-right: 24px !important;
  }

  &.mrg-r-sm {
    margin-right: 16px !important;
  }

  &.mrg-r-xs {
    margin-right: 8px !important;
  }

  &.mrg-r-xxs {
    margin-right: 4px !important;
  }

  &.mrg-b-lg {
    margin-bottom: 32px !important;
  }

  &.mrg-b-xl {
    margin-bottom: 40px !important;
  }

  &.mrg-b-md {
    margin-bottom: 24px !important;
  }

  &.mrg-b-sm {
    margin-bottom: 16px !important;
  }

  &.mrg-b-xs {
    margin-bottom: 8px !important;
  }

  &.mrg-b-xxs {
    margin-bottom: 4px !important;
  }

  &.mrg-l-lg {
    margin-left: 32px !important;
  }

  &.mrg-l-md {
    margin-left: 24px !important;
  }

  &.mrg-l-sm {
    margin-left: 16px !important;
  }

  &.mrg-l-xs {
    margin-left: 8px !important;
  }

  &.mrg-l-xxs {
    margin-left: 4px !important;
  }

  // ELEMENT MARGINS RESPONSIVE

  &.mrg-b-xs-to-sm {
    margin-bottom: 8px !important;

    @include mq($from: desktop-md) {
      margin-bottom: 16px !important;
    }
  }

  &.mrg-b-md-to-lg {
    margin-bottom: 24px !important;

    @include mq($from: desktop-md) {
      margin-bottom: 32px !important;
    }
  }

  &.mrg-t-xs-to-sm {
    margin-top: 8px !important;

    // @include mq($from: desktop-md) {
    //   margin-top: 16px !important;
    // }
  }

  &.wdt-full {
    width: 100%;
  }
}

/*------------------------------------*\
        $CHIPS BASIC & STATUS
\*------------------------------------*/
.mat-mdc-standard-chip {
  &.mdc-evolution-chip--with-trailing-action {
    .mdc-evolution-chip__action--primary {
      padding: 0 12px !important;
    }
  }
}

.mat-mdc-chip {
  &.mat-primary {
    .mdc-evolution-chip__text-label {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    button {
      margin: 0 8px;

      .mat-icon {
        color: var(--brndPrimary0500);
      }
    }

    &.basic {
      align-items: center;
      border-radius: var(--buXS);
      display: flex;
      font-family: var(--tfBody);
      justify-content: center;
      min-height: 32px;
      padding: var(--buXS);
      min-width: 40px;

      button {
        background-color: transparent;
        // border: 1px solid tomato;
        cursor: pointer;
        margin: 0 0 0 8px;
        padding: 2px 0 0 0;
      }

      // Type
      &.business01 {
        background-color: var(--bsn01) !important; // Override Material Styles
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txInverted) !important; // Override Material Styles
        }
      }

      &.business02 {
        background-color: var(--bsn02) !important; // Override Material Styles
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txInverted) !important; // Override Material Styles
        }
      }

      &.business03 {
        background-color: var(--bsn03) !important; // Override Material Styles
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txInverted) !important; // Override Material Styles
        }
      }

      &.businessDefault {
        background-color: var(--bgFrame) !important; // Override Material Styles
        color: var(--txSecondary) !important; // Override Material Styles
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txSecondary) !important; // Override Material Styles
        }
      }
    }

    // TYPE
    &.info {
      background-color: var(--bgDepth) !important; // Override Material Styles
      pointer-events: none;
      user-select: none;

      .mdc-evolution-chip__text-label {
        color: var(--txSecondary) !important; // Override Material Styles
      }
    }

    &.secondary {
      &.basic {
        background-color: var(--bgPrimary) !important; // Override Material Styles
        cursor: pointer;

        .mdc-evolution-chip__text-label {
          color: var(--txInverted) !important; // Override Material Styles
        }

        button {
          background-color: transparent;
          cursor: pointer;
          margin: 0 0 0 8px;
          padding: 0;

          .mat-icon {
            color: var(--txInverted);
            cursor: pointer;

            &:hover {
              color: var(--txBrndSecondary);
            }
          }
        }
      }
    }

    &.white {
      background-color: var(--bgSurface) !important; // Override Material Styles
      pointer-events: none;
      user-select: none;

      .mdc-evolution-chip__text-label {
        color: var(--txSecondary) !important; // Override Material Styles
      }
    }

    // Shape
    &.rounded {
      border-radius: var(--buLG);
      padding: 0;
      min-width: unset;
    }

    // STATUS
    &.status {
      &.error {
        background-color: var(--bgErrorOpacity) !important; // Override Material Styles
        // border-radius: 50%;
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txError) !important; // Override Material Styles
        }
      }

      &.success {
        background-color: var(--bgSuccessOpacity) !important; // Override Material Styles
        // border-radius: 50%;
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txSuccessFull) !important; // Override Material Styles
        }
      }

      &.warning {
        background-color: var(--bgWarningOpacity) !important; // Override Material Styles
        // border-radius: 50%;
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txWarning) !important; // Override Material Styles
        }
      }

      &.green {
        background-color: var(--txSuccess) !important; // Override Material Styles
        border-radius: var(--buMD);
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txInverted) !important; // Override Material Styles
        }
      }
    }

    // Custom
    &.grey {
      background-color: var(--cst01) !important; // Override Material Styles
      // border-radius: var(--buMD) !important;

      .mdc-evolution-chip__text-label {
        color: var(--txInverted) !important; // Override Material Stylesz
      }
    }

    &.primary20 {
      background-color: var(--brndPrimary124) !important; // Override Material Styles

      .mdc-evolution-chip__text-label {
        color: var(--brndPrimary0500) !important; // Override Material Styles
      }
    }

    &.primary40 {
      background-color: var(--cst05) !important; // Override Material Styles

      .mdc-evolution-chip__text-label {
        color: var(--txInverted) !important; // Override Material Styles
      }
    }

    &.primary64 {
      background-color: var(--cst04) !important; // Override Material Styles

      .mdc-evolution-chip__text-label {
        color: var(--txInverted) !important; // Override Material Styles
      }
    }

    &.secondary {
      background-color: var(--cst03) !important; // Override Material Styles

      .mdc-evolution-chip__text-label {
        color: var(--txInverted) !important; // Override Material Styles
      }
    }

    &.secondary80 {
      background-color: var(--cst02) !important; // Override Material Styles

      .mdc-evolution-chip__text-label {
        color: var(--txInverted) !important; // Override Material Styles
      }
    }

    &.critical80 {
      background-color: var(--cst06) !important; // Override Material Styles

      .mdc-evolution-chip__text-label {
        color: var(--txInverted) !important; // Override Material Styles
      }
    }
  }
}

/*------------------------------------*\
              $CHIPS STATUS
\*------------------------------------*/
.mat-mdc-chip {
  &.mat-primary {
    &.status {
      align-items: center;
      border-radius: var(--buSM);
      display: flex;
      justify-content: center;
      min-height: 32px;
      padding: 4px 12px;
      min-width: 80px;

      // Type
      &.info {
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txSecondary) !important; // Override Material Styles
        }
      }

      &.error {
        background-color: var(--bgErrorOpacity) !important; // Override Material Styles
        border-radius: var(--buMD);
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txError) !important; // Override Material Styles
        }
      }

      &.success {
        background-color: var(--bgSuccessOpacity) !important; // Override Material Styles
        border-radius: var(--buMD);
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txSuccess) !important; // Override Material Styles
        }
      }

      &.warning {
        background-color: var(--bgWarningOpacity) !important; // Override Material Styles
        border-radius: var(--buMD);
        pointer-events: none;
        user-select: none;

        .mdc-evolution-chip__text-label {
          color: var(--txWarning) !important; // Override Material Styles
        }
      }

      // Custom
      &.custom {
        &.grey {
          background-color: var(--cst01) !important; // Override Material Styles
          border-radius: var(--buMD);
          pointer-events: none;
          user-select: none;

          .mdc-evolution-chip__text-label {
            color: var(--txInverted) !important; // Override Material Styles
          }
        }

        &.primary40 {
          background-color: var(--cst05) !important; // Override Material Styles
          pointer-events: none;
          user-select: none;

          .mdc-evolution-chip__text-label {
            color: var(--txInverted) !important; // Override Material Styles
          }
        }

        &.primary64 {
          background-color: var(--cst04) !important; // Override Material Styles
          pointer-events: none;
          user-select: none;

          .mdc-evolution-chip__text-label {
            color: var(--txInverted) !important; // Override Material Styles
          }
        }

        &.secondary {
          background-color: var(--cst03) !important; // Override Material Styles
          pointer-events: none;
          user-select: none;

          .mdc-evolution-chip__text-label {
            color: var(--txInverted) !important; // Override Material Styles
          }
        }

        &.secondary80 {
          background-color: var(--cst02) !important; // Override Material Styles
          pointer-events: none;
          user-select: none;

          .mdc-evolution-chip__text-label {
            color: var(--txInverted) !important; // Override Material Styles
          }
        }

        &.critical80 {
          background-color: var(--cst06) !important; // Override Material Styles
          pointer-events: none;
          user-select: none;

          .mdc-evolution-chip__text-label {
            color: var(--txInverted) !important; // Override Material Styles
          }
        }
      }

      &.aling-end {
        justify-content: flex-end !important;
      }

      &.rounded {
        border-radius: var(--buLG);
        padding: 0;
        min-width: unset;
      }
    }
  }
}

/*------------------------------------*\
                $DIALOG
\*------------------------------------*/
.mat-mdc-dialog-container {
  border-radius: var(--buMD) !important;
}

.cdk-overlay-pane {
  &.dialog--sm {
    .mdc-dialog__container {
      height: 400px;
      width: 440px;

      .mat-mdc-dialog-container,
      .mdc-dialog__surface {
        border-radius: var(--buMD) !important;
        height: auto;
      }

      .content-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 100%;
      }

      .feedback-icon {
        align-items: center;
        display: flex;
        height: 104px;
        justify-content: center;
        margin-bottom: 16px;
        width: 104px;

        svg {
          #icon__interaction-target {
            height: 104px;
            width: 104px;
          }
        }
      }

      .dialog__inner__actions {
        justify-content: center;
      }

      .dialog__inner__body__content {
        flex: initial;
      }
    }
  }

  &.dialog--md {
    .mdc-dialog__container {
      height: 600px;
      width: 600px;

      .mat-mdc-dialog-container,
      .mdc-dialog__surface {
        border-radius: var(--buMD) !important;
        height: auto;
      }

      .content-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 100%;
      }

      .feedback-icon {
        align-items: center;
        display: flex;
        height: 104px;
        justify-content: center;
        margin-bottom: 16px;
        width: 104px;

        svg {
          #icon__interaction-target {
            height: 104px;
            width: 104px;
          }
        }
      }
    }
  }

  &.dialog--lg {
    .mdc-dialog__container {
      // height: 600px;
      min-height: 600px;
      width: 800px;
      overflow: hidden !important;

      .mat-mdc-dialog-container,
      .mdc-dialog__surface {
        border-radius: var(--buMD) !important;
        height: auto;
        overflow: hidden;
      }
    }

    .feedback-icon {
      align-items: center;
      display: flex;
      height: 104px;
      justify-content: center;
      margin-bottom: 16px;
      width: 104px;

      svg {
        #icon__interaction-target {
          height: 104px;
          width: 104px;
        }
      }
    }
  }

  &.dialog--full {
    .mdc-dialog__container {
      height: 100vh;
      width: 100vw;
      overflow: hidden !important;

      .mat-mdc-dialog-container,
      .mdc-dialog__surface {
        border-radius: var(--buMD) !important;
      }
    }
  }
}

.dialog {
  &__inner {
    height: 100%;
    // height: calc(100% - 48px) !important;
    padding: 24px;
    // width: calc(100% - 48px) !important;
    width: 100%;

    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      width: 100%;
    }

    &__body {
      align-items: center;
      // border: 1px solid gold;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      // overflow: auto;
      overflow: hidden;

      &__content {
        padding: 8px;
      }
    }

    &__actions {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 48px;
      width: 100%;

      &.center {
        justify-content: center;
      }

      .btn-wrapper {
        margin-left: 16px;
      }
    }
  }
}

/*------------------------------------*\
          $SVG EDITS FOR BG
\*------------------------------------*/
.column--right {
  .icon-wrapper {
    height: 104px;
    width: 104px;
  }

  svg {
    margin-right: 16px;

    &#icon {
      height: 80px;
      width: 80px;
    }

    #icon__interaction-target {
      fill: var(--bgDisabled);
    }
  }
}

/*------------------------------------*\
          $SVG EDITS TICKETS
\*------------------------------------*/
.icon-wrapper {
  &.primary {
    svg {
      #icon__interaction-target {
        fill: var(--brndPrimary0500);
      }
    }
  }

  &.success {
    svg {
      #icon__interaction-target {
        fill: var(--txSuccess);
      }
    }
  }

  &.error {
    svg {
      #icon__interaction-target {
        fill: var(--txError);
      }
    }
  }
}

.route-wrapper {
  .icon-wrapper {
    padding: 4px;

    svg {
      #icon__interaction-target {
        fill: var(--bgDisabled);
      }
    }
  }
}

/*------------------------------------*\
  $SVG EDITS FOR STATUSES ON PASS CONC  
\*------------------------------------*/
.icon-wrapper {
  height: 24px;
  width: 24px;

  &.success {
    svg {
      #icon__interaction-target {
        fill: var(--txSuccess);
      }
    }
  }

  &.failure {
    svg {
      #icon__interaction-target {
        fill: var(--txError);
      }
    }
  }
}

/*------------------------------------*\
          $CARD NO RESULTS/EMPTY
\*------------------------------------*/
// <div class="card--no-results">
// <img src="assets/animations/animation--no-results.gif" alt="" />
// <p>{{ stateLabels?.emptyTable }}</p>
// </div>
.card--no-results {
  // align-items: center;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // margin-bottom: 32px;
  // padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  // img {
  //   // max-width: 200px;
  //   // width: 60%;
  // }
}

/*------------------------------------*\
              $CARD SPECIAL
\*------------------------------------*/
.card {

  // Special
  &.special {
    height: 100%;

    .card__body {
      // border: 1px solid tomato;
      display: block;
      // height: 250px;
      height: 100%;
      width: 100%;

      @include mq($from: desktop-md) {
        display: inline-block;
        // max-width: 100%;
        // height: 600px;
      }
    }
  }
}

/*------------------------------------*\
      $INPUT RESET MATERIAL STYLES
\*------------------------------------*/

/*------------------------------------*\
            $INPUT GENERAL
\*------------------------------------*/
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-color: var(--bgFrame) !important;
  border: 2px solid var(--brndPrimary0500) !important;
  box-sizing: border-box !important;
  color: var(--txBrndPrimary) !important;
  font-weight: 500 !important;
  -webkit-text-fill-color: var(--txBrndPrimary);
  -webkit-box-shadow: 0 0 0px 1000px var(--sdwNeutral) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

div {
  &.ng-untouched {
    &.ng-pristine {
      width: inherit;
    }
  }
}

.form {
  &__input-wrapper {
    font-family: var(--tfBody);
    font-weight: 700;
    display: initial;
    position: relative;
    width: 100%;

    &.primary {

      // label
      .content {
        align-items: center;
        background-color: transparent;
        border-radius: var(--buSM);
        display: flex;
        justify-content: space-between;
        margin: 0 0 8px;
        padding: 0;
        z-index: 1000;

        p {
          @include x-rem(font-size, 14px);
          @include x-rem(line-height, 18px);
          color: var(--txBrndPrimary);
          font-weight: 400;
          margin: 0;
        }
      }

      // input
      input {
        @include x-rem(font-size, 16px);
        @include x-rem(line-height, 24px);
        // A reset of styles
        appearance: none;
        background-color: var(--bgSurface);
        border: 2px solid var(--brndPrimary0500);
        border-radius: var(--buSM);
        color: var(--txBrndPrimary) !important;
        height: map-get($input, height);
        margin: 0 0 8px;
        padding: 12px 48px 12px 16px;
        width: 100%;

        &:disabled {
          background-color: var(--bgDisabled);
          border: 0;
          color: var(--txDisabled) !important;
        }
      }

      ::placeholder {
        @include x-rem(font-size, 14px);
        @include x-rem(line-height, 24px);
        color: var(--txDisabled);
        font-weight: 400;
      }

      // error badge
      img {
        &.error {
          // filter: invert(1) sepia(10) saturate(16) hue-rotate(328deg);
          height: 24px;
          position: absolute;
          right: 16px;
          top: calc(50% + 8px);
          width: 24px;
        }

        &.valid {
          // filter: invert(57%) sepia(76%) saturate(386%) hue-rotate(58deg) brightness(94%) contrast(82%);
          height: 24px;
          position: absolute;
          right: 16px;
          top: calc(50% + 8px);
          width: 24px;

          &.no-label {
            top: calc(50% + 0px);
          }
        }
      }

      &.no-label {
        img {

          &.error,
          &.valid {
            top: calc(50% + 0px);
          }
        }
      }

      &.error {
        input {
          background-color: var(--bgErrorOpacity) !important;
          border: 2px solid var(--txError);
          color: var(--txError) !important;
        }
      }

      &.success {
        input {
          background-color: var(--bgSuccessOpacity) !important;
          border: 2px solid var(--txSuccess);
          color: var(--txSuccess) !important;
        }
      }
    }

    input,
    textarea,
    select {
      box-sizing: border-box !important;

      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: 2px solid var(--bgFrame) !important;
        border: 0;
        background-color: var(--bgSurfaceAlt) !important;
      }
    }
  }
}

.input {
  &__feedback {
    &.error {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin: 0 0 24px;
      padding: 0;
      width: 100%;

      p {
        @include x-rem(font-size, 14px);
        color: var(--txError);
        font-family: var(--tfBody);
        font-weight: 500;
        margin: 4px 0 0 0;
        padding: 0;
        width: 100%;
      }

      img {
        align-items: center;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

// /*------------------------------------*\
//             $INPUT DATE
// \*------------------------------------*/
.mat-mdc-form-field {
  &.input__datepicker {
    background-color: var(--bgFrame);
    border: 2px solid var(--brndPrimary0500);
    border-radius: var(--buSM);
    margin-bottom: 8px;
    width: 100%;

    .mat-mdc-form-field-wrapper {
      padding: 0;
    }

    .mdc-text-field {
      border-radius: var(--buSM);
      padding: 0 0 0 12px;
    }

    .mat-mdc-form-field-flex {
      @include x-rem(font-size, 16px);
      align-items: center;
      background-color: var(--bgFrame);
      border: 0;
      border-radius: var(--buSM);
      color: var(--txPrimary) !important;
      font-family: var(--tfBody);
      height: map-get($input, height);
      padding: 4px 0px 4px 0;

      &:disabled {
        background-color: var(--bgDisabled);
        border: 0;
        color: var(--txDisabled) !important;
      }
    }

    .mdc-text-field--filled {
      background-color: transparent !important;
    }

    .mat-mdc-form-field-infix {
      border: 0;
      min-height: unset;
      padding: 8px 0;
      width: fit-content;
    }

    .mat-mdc-form-field-underline,
    .mat-mdc-form-field-label-wrapper,
    .mdc-line-ripple {
      display: none;
    }

    .mdc-text-field__input {
      color: var(--txPrimary) !important;
    }

    svg {
      fill: var(--brndPrimary0500) !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    &.range {
      .mat-mdc-form-field-required-marker {
        display: none;
      }
    }

    &.mat-mdc-form-field {
      .mat-mdc-form-field-flex {
        .mat-mdc-floating-label {
          &.mdc-floating-label--float-above {
            display: none;
          }
        }
      }

      .mat-mdc-form-field-required-marker {
        display: none;
      }
    }
  }
}

/*------------------------------------*\
            $INPUT PASSWORD
\*------------------------------------*/
.form {
  &__input-wrapper {
    &.password {
      img {
        border-radius: var(--buSM);
        cursor: pointer;
        height: 32px;
        padding: 4px;
        position: absolute;
        right: 16px;
        transition: box-shadow 300ms ease-in;
        top: calc(50% - 8px);
        width: 32px;

        @include mq($from: desktop) {
          top: calc(50% - 2px);

          &:hover {
            box-shadow: var(--sdwBrand);
          }
        }
      }
    }
  }
}

/*------------------------------------*\
    $INPUT SELECT ON PASS/CONC PAGES
\*------------------------------------*/
.row--txt {
  label {
    &.form__input-wrapper {
      &.select {
        .arrow {
          top: 18px !important;
        }
      }
    }
  }
}

/*------------------------------------*\
    $INPUT SELECT on EMV
\*------------------------------------*/
.row {
  .inner {
    .right {
      .form {
        &__input-wrapper {
          &.select {
            &.hidden {
              .arrow {
                right: 8px;
              }

              select {
                @include x-rem(font-size, 16px !important);
                box-sizing: border-box !important;
                font-weight: 500 !important;
                height: 40px;
                margin: 0 0 2px 0;
                padding: 0 36px 0 8px;

                &:focus,
                &:focus-visible,
                &:focus-within {
                  background-color: var(--bgSecondary);
                  border: 0;
                  color: var(--txSecondary) !important;
                  outline: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*------------------------------------*\
          $INPUT SLIDE TOGGLE
\*------------------------------------*/
.mat-mdc-slide-toggle {
  margin: 0 0 24px;

  .mdc-switch {
    background-color: var(--bgSurfaceAlt);
    border-radius: 20px;
    color: var(--bgSurface);
    height: 32px;
    margin-right: 8px;
    padding: 1px 9px;
    width: 60px !important;

    &--unselected {
      .mdc-switch__track {
        border-radius: var(--buLG) !important;
        left: 42px;
        overflow: visible;
        width: 60px !important;

        &:before {
          content: none !important;
        }

        &:after {
          background-color: var(--bgSurface) !important;
        }
      }

      .mdc-switch__ripple {
        display: none;
      }

      .mdc-switch__handle {
        border-radius: 50% !important;
        height: 24px !important;
        left: 8px;
        width: 24px !important;
      }

      .mdc-switch__icons {
        background: var(--bgFrame);
        border-radius: 50%;
      }

      &:enabled {
        .mdc-switch__track {
          &:after {
            background-color: var(--bgSurfaceAlt) !important;
          }
        }
      }
    }

    &--selected {
      .mdc-switch__track {
        border-radius: var(--buLG);
        height: 32px;
        width: 60px !important;

        &:before {
          content: none !important;
        }

        &:after {
          background-color: var(--bgSurfaceAlt);
        }
      }

      .mdc-switch__ripple {
        display: none;
      }

      .mdc-switch__handle {
        border-radius: 50% !important;
        height: 24px !important;
        left: -8px;
        width: 24px !important;

        &:after {
          background-color: var(--brndPrimary0500) !important;
        }
      }

      &:enabled {
        .mdc-switch__track {
          &:after {
            background-color: var(--brndPrimary140) !important;
          }
        }
      }
    }

    .mdc-elevation-overlay {
      left: -8px;
    }
  }

  label {
    @include x-rem(font-size, 16px);
    @include x-rem(line-height, 28px);
    color: var(--txSecondary);
    font-family: var(--tfBody);
    font-weight: 400;
  }

  &-checked {
    label {
      color: var(--txBrndPrimary);
      font-weight: 500;
    }
  }
}

/*------------------------------------*\
              $PAGINATOR
\*------------------------------------*/
.mat-mdc-paginator {
  background-color: var(--bgSurfaceAlt);
  border-radius: var(--buSM);
  color: var(--txSecondary);
  margin: 8px 0 0;
  padding: 0 16px;
  width: 100%;
}

.mat-mdc-paginator-container {
  justify-content: space-between !important;

  @include mq($from: tablet) {
    justify-content: space-between;
  }

  .mat-mdc-paginator-page-size-label {
    color: var(--txSecondary);
    font-family: var(--tfBody);
    font-weight: 400;
  }

  .mat-mdc-paginator-page-size {
    align-items: center;
    // background-color: var(--bgSurface);
    border-radius: var(--buSM);
    display: flex;
    height: 44px;
    justify-content: center;
    margin-bottom: 10px;
    padding: 8px;

    @include mq($from: tablet) {
      margin-bottom: 0;
    }

    .mat-mdc-select-value {
      text-align: center;
    }

    .mat-mdc-select-value-text {
      color: var(--txSecondary);
      font-family: var(--tfBody);
    }

    .mat-mdc-select-arrow {
      color: var(--txSecondary);
    }

    .mat-mdc-form-field-underline {
      display: none;
    }
  }

  .mat-mdc-form-field {
    background-color: var(--bgSurface);
    border-radius: var(--buSM);

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border: 0;
    }
  }
}

.mat-select-panel {
  box-shadow: var(--shadowsPrimary);
}

.mat-paginator-range-actions {
  background-color: var(--bgSurfaceAlt);
  border-radius: var(--buSM);
  font-family: var(--tfBody);

  .mat-icon-button {
    margin-bottom: 4px;
  }
}

/*------------------------------------*\
              $SECTION BG
\*------------------------------------*/
// .section__bg {
//   background-color: var(--bgFrame);
//   border: 1px solid tomato;
//   border-radius: var(--buSM);
//   box-shadow: var(--sdwNeutral);
//   display: flex;
//   flex: 1;
//   // height: 100%;
//   min-height: 324px;
//   margin: 0;
//   padding: 16px;
//   // width: 100%;

//   // &.chart {
//   //   padding: 0 0 16px;
//   //   width: calc(100% - 32px);
//   // }

//   // &.chart-inner {
//   //   margin: 16px auto 0;
//   //   padding: 0;
//   // }
// }

/*------------------------------------*\
                $TABS
\*------------------------------------*/
.tabs-wrapper {
  // border: 1px solid tomato;
  height: 100%;
  margin: 0;
  flex-direction: row;
  width: 100%;

  &.align-end {
    justify-content: flex-end;

    .mat-mdc-tab-group {
      &.horizontal {
        .mat-mdc-tab-header {
          border-bottom: 0;
        }

        .mat-mdc-tab-labels {
          justify-content: flex-end;
        }
      }
    }

    .mat-mdc-tab-labels {
      justify-content: flex-end;
    }
  }

  .mat-mdc-tab-group {
    // border: 1px solid pink;
    height: 100%;

    &.vertical {
      // border: 1px solid pink;
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      overflow: hidden;

      .mat-mdc-tab-body-content {
        // border: 1px solid black;
        height: 100%;
        overflow: hidden !important;
        // margin: 8px;
        // height: calc(100% - 8px);
        // overflow-x: hidden;
        // width: calc(100% - 8px);
      }

      .mat-mdc-tab-body {
        &.mat-mdc-tab-body-active {
          overflow: hidden;
        }
      }

      .mat-mdc-tab-header {
        // border: 1px solid black;
        border-top-left-radius: var(--buSM);
        border-bottom-left-radius: var(--buSM);
        height: 100%;

        @include mq($from: desktop) {
          max-width: 80px;
        }

        @include mq($from: desktop-lg) {
          max-width: unset;
        }
      }

      .mat-mdc-tab-labels {
        align-items: flex-start;
        // border: 1px solid tomato;
        display: flex;
        flex-direction: column;
        // height: 100%;
        justify-content: flex-start;
      }

      .mdc-tab__text-label {
        height: 20px;
        width: 20px;

        @include mq($from: desktop-lg) {
          height: 40px;
          width: 40px;
        }
      }

      .mat-mdc-tab-body-wrapper {
        // border: 1px solid black;
        border-left: 2px solid var(--bgSurfaceAlt);
        height: 100%;
        padding: 8px 8px 8px 0;
        width: 100%;
      }

      .mat-mdc-tab {
        // border: 1px solid gold;
        height: 56px;
        width: 56px;
        margin: 0 0 8px;
        padding: 0;
        min-width: unset;

        @include mq($from: desktop-lg) {
          height: 80px;
          width: 80px;
        }

        &.mdc-tab-indicator--active {
          border-right: 4px solid var(--brndPrimary0500);
          // border-radius: var(--buMD);

          .icon-wrapper {
            svg {
              #icon__interaction-target {
                fill: var(--brndPrimary0500);
              }
            }
          }
        }
      }

      .mdc-tab-indicator {

        .mdc-tab-indicator__content,
        &.mdc-tab-indicator__content--underline {
          border: 0;
        }
      }

      .mdc-tab-indicator {
        &__content--underline {
          border-color: var(--brndPrimary0500);
          border-top-width: 4px;
          border-top-left-radius: var(--buXS);
          border-top-right-radius: var(--buXS);
        }
      }

      .mdc-tab {
        border-top-left-radius: var(--buSM);
        border-bottom-left-radius: var(--buSM);

        @include mq($from: desktop-lg) {
          border-top-left-radius: var(--buMD);
          border-bottom-left-radius: var(--buMD);
        }

        &:hover {
          background-color: var(--brndPrimary116);
        }

        &__ripple {
          &::before {
            border-top-left-radius: var(--buSM);
            border-bottom-left-radius: var(--buSM);

            @include mq($from: desktop-lg) {
              border-top-left-radius: var(--buMD);
              border-bottom-left-radius: var(--buMD);
            }
          }
        }

        .mat-mdc-tab-ripple {
          border-top-left-radius: var(--buSM);
          border-bottom-left-radius: var(--buSM);

          @include mq($from: desktop-lg) {
            border-top-left-radius: var(--buMD);
            border-bottom-left-radius: var(--buMD);
          }
        }
      }

      .content-wrapper {
        // border: 1px solid royalblue;
        overflow: auto;
        overflow-x: hidden;

        .tab {
          // margin: 4px;
        }
      }
    }

    &.horizontal {

      // default styles
      .mat-mdc-tab-header {
        align-items: center;
        background-color: var(--bgSurface);
        border-bottom: 2px solid var(--bgSurfaceAlt);
        border-top-left-radius: var(--buMD);
        border-top-right-radius: var(--buMD);
        display: flex;
        flex-direction: row;
        justify-content: center;

        svg {
          #icon__interaction-target {
            fill: var(--txSecondary);
          }

          path {
            &#icon__interaction-target {
              fill: var(--txSecondary);
            }
          }
        }
      }

      .tab-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .icon-wrapper {
          height: 24px;
          margin-bottom: 8px !important;
          width: 24px;

          svg {
            #icon__interaction-target {
              fill: var(--brndPrimary140);
            }
          }
        }
      }

      .mat-mdc-tab-body-wrapper {
        align-items: flex-start;
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: auto;
        width: 100%;
      }

      .mat-mdc-tab-body {
        // border: 1px solid black;
        height: 100%;
      }

      .mat-mdc-tab {
        flex-grow: unset !important;

        &.mdc-tab {
          height: 100% !important;
        }
      }

      .mdc-tab {
        border-top-left-radius: var(--buMD);
        border-top-right-radius: var(--buMD);
        // margin-left: 8px;
        padding-top: 8px;

        &:hover {
          background-color: var(--brndPrimary116);
        }

        &__ripple {
          &::before {
            border-top-left-radius: var(--buMD);
            border-top-right-radius: var(--buMD);
          }
        }

        .mat-mdc-tab-ripple {
          border-top-left-radius: var(--buMD);
          border-top-right-radius: var(--buMD);
        }

        &__content {
          margin-bottom: 12px;

          .mdc-tab__text-label {
            p {
              color: var(--brndPrimary140);
            }
          }
        }

        &.mdc-tab--active {
          .mdc-tab__text-label {
            p {
              color: var(--brndPrimary0500);
            }
          }

          .icon-wrapper {
            // border: 1px solid tomato;
            height: 24px;
            margin-bottom: 8px !important;
            width: 24px;

            svg {
              #icon__interaction-target {
                fill: var(--brndPrimary0500);
              }
            }
          }
        }
      }

      .mdc-tab-indicator {
        &__content--underline {
          border-color: var(--brndPrimary0500);
          border-top-width: 4px;
          border-top-left-radius: var(--buXS);
          border-top-right-radius: var(--buXS);
        }
      }

      .mat-mdc-tab-labels {
        justify-content: flex-start;

        &.mat-mdc-focus-indicator {
          &.mat-mdc-tab-label-active {
            p {
              &.text {
                color: var(--brndPrimary0500);
              }
            }
          }
        }
      }

      // .mdc-tab--active {
      //   .mdc-tab__text-label {
      //     color: var(--brndPrimary0500);
      //     font-weight: 600;
      //   }
      // }

      // .tab-wrapper {
      //   align-items: center;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;

      //   .icon-wrapper {
      //     // border: 1px solid tomato;
      //     height: 24px;
      //     margin-bottom: 8px !important;
      //     width: 24px;

      //     svg {
      //       #icon__interaction-target {
      //         fill: var(--brndPrimary0500);
      //       }
      //     }
      //   }
      // }
    }
  }

  &.horizontal {
    &.small {
      .mat-mdc-tab-group {
        &.horizontal {
          .mat-mdc-tab-header {
            border-bottom: 0;
          }

          .mdc-tab__content {
            .tab-wrapper {
              flex-direction: row;

              .icon-wrapper {
                height: 16px;
                margin-right: 8px;
                margin-bottom: 2px !important;
                width: 16px;
              }
            }
          }

          .mdc-tab {
            border-top-left-radius: var(--buXS);
            border-top-right-radius: var(--buXS);
            padding-top: 16px;

            .mat-mdc-tab-ripple {
              border-top-left-radius: var(--buXS);
              border-top-right-radius: var(--buXS);
            }
          }
        }
      }
    }
  }
}

/*------------------------------------*\
                $TABLE
\*------------------------------------*/
.mat-mdc-table {
  display: none;
  pointer-events: none;
  user-select: none;

  @include mq($from: tablet) {
    background-color: var(--bgSurface);
    display: block;
    pointer-events: auto;
    position: relative;
    user-select: auto;
  }

  .mat-mdc-header-row {
    background-color: var(--bgSurfaceAlt);
    border-radius: var(--buSM);
    border: 0;
    margin-bottom: 8px;

    .mat-mdc-header-cell {
      // border: 1px solid tomato !important; // TESTING BORDER
      @include x-rem(font-size, 16px);
      align-items: center;
      border: 0;
      border-radius: var(--buSM);
      color: var(--txSecondary);
      display: flex;
      font-family: var(--tfBody);
      justify-content: center;
      margin: 0;
      text-align: center;

      // HEADER CUSTOMISATIONS
      &:first-of-type,
      &:last-of-type {
        padding: 0 !important;
      }

      &.mat-column-actions,
      &.mat-column-purchasesSvg {
        max-width: 144px;
      }

      &.mat-column-fiscalCode {
        min-width: 200px;
      }

      &.mat-column-status {
        padding: 0 8px;
      }

      // &.mat-column-name,
      // &.mat-column-editedAt,
      // &.mat-column-editedBy {
      //   max-width: 304px;
      // }

      // &.mat-column-actions {
      //   position: absolute;
      //   right: 48px;
      // }

      &.xl {
        overflow: auto;
        padding: 0 48px;
      }

      .mat-mdc-sort-header-arrow {
        position: absolute;
        right: -15px;
      }
    }
  }

  .mat-mdc-row {
    background-color: var(--bgFrame);
    // border-radius: var(--buSM);
    border: 0;
    // cursor: pointer;
    margin-bottom: 4px;

    &:hover {
      background-color: var(--bgHoverAlt) !important;
    }

    .mat-mdc-cell {
      // border: 1px solid tomato !important; // TESTING BORDER
      @include x-rem(font-size, 14px);
      align-items: center;
      border: 0;
      color: var(--txSecondary);
      display: flex;
      font-family: var(--tfBody);
      font-weight: 400;
      justify-content: center;
      margin: 0;
      text-align: center;

      &:first-of-type,
      &:last-of-type {
        padding: 0 !important;
      }

      /*------------------------------------*\
                $CELLS CUSTOMISATIONS
      \*------------------------------------*/
      &.mat-column-reviewedDate {
        text-transform: capitalize;
      }

      &.mat-column-actions,
      &.mat-column-purchasesSvg {
        max-width: 144px;
      }

      &.mat-column-fiscalCode {
        min-width: 200px;
      }

      &.mat-column-status {
        padding: 0 8px;
      }

      // &.mat-column-name,
      // &.mat-column-editedAt,
      // &.mat-column-editedBy {
      //   max-width: 304px;
      // }

      table-cell-actions {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      // &.mat-mdc-column-codeAlarmsList {
      //   overflow: auto;

      //   @include mq($from: tablet) {
      //     overflow: initial;
      //     max-width: 200px;
      //     padding: 0;
      //   }

      //   @include mq($from: desktop) {
      //     max-width: 280px;
      //   }

      //   @include mq($from: large-screen) {
      //     max-width: initial;
      //   }
      // }
    }
  }
}

.mat-mdc-table {
  svg {
    height: 24px;
    width: 24px;

    // #icon__interaction-target {
    //   fill: var(--txSecondary);
    // }
  }
}

.card__table--mobile {
  svg {
    height: 24px;
    width: 24px;

    // #icon__interaction-target {
    //   fill: var(--txSecondary);
    // }
  }
}

// TABLE AND WARNING ROWS
.table-wrapper {
  &.warning {
    .card__table--mobile {
      background-color: var(--bgWarningOpacity) !important;
    }

    .mat-mdc-table {
      .mat-mdc-row {
        background-color: var(--bgWarningOpacity) !important;
      }
    }
  }

  &.web-account {
    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &.mat-column-email {
            min-width: 360px;
          }
        }
      }

      .mat-mdc-row {
        .mat-mdc-cell {
          &.mat-column-email {
            min-width: 360px;
          }
        }
      }
    }
  }

  &.emv-table {
    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {

          &.mat-column-amountFormatted,
          &.mat-column-amountRefundFormatted {
            max-width: 104px;
          }

          &.mat-column-cardNumber {
            max-width: 144px;
          }

          &.mat-column-date,
          &.mat-column-actions {
            max-width: 200px;
          }
        }
      }

      .mat-mdc-row {
        .mat-mdc-cell {

          &.mat-column-amountFormatted,
          &.mat-column-amountRefundFormatted {
            max-width: 104px;
          }

          &.mat-column-cardNumber {
            max-width: 144px;
          }

          &.mat-column-date,
          &.mat-column-actions {
            max-width: 200px;
          }

          &.mat-column-amountRefundFormatted {
            table-cell-type {
              span {
                color: var(--txSuccess);
              }
            }
          }
        }
      }
    }
  }

  &.external-sales {
    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &.mat-column-note {
            min-width: 400px;
          }
        }
      }

      .mat-mdc-row {
        .mat-mdc-cell {
          &.mat-column-note {
            min-width: 400px;
          }
        }
      }
    }
  }

  &.orders-main {
    .mat-mdc-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          padding: 0 8px;

          &.mat-column-date {
            max-width: 144px;
          }

          &.mat-column-id {
            max-width: 144px;
          }

          &.mat-column-status {
            max-width: 144px;
          }

          &.mat-column-totalAmountFormatted {
            max-width: 104px;
          }

          &.mat-column-actions {
            max-width: 80px;
          }

          &.mat-column-salesChannel {
            max-width: 144px;
          }

          &.mat-column-transactionCode,
          &.mat-column-id {
            span {
              @include x-rem(font-size, 12px);
            }
          }

          // &.mat-column-email {
          //   min-width: 360px;
          // }
        }
      }

      .mat-mdc-row {
        .mat-mdc-cell {
          padding: 0 8px;

          &.mat-column-date {
            max-width: 144px;
          }

          &.mat-column-id {
            max-width: 144px;
          }

          &.mat-column-status {
            max-width: 144px;
          }

          &.mat-column-totalAmountFormatted {
            max-width: 104px;
          }

          &.mat-column-actions {
            max-width: 80px;
          }

          &.mat-column-salesChannel {
            max-width: 144px;
          }

          &.mat-column-transactionCode,
          &.mat-column-id {
            span {
              @include x-rem(font-size, 12px);
            }
          }

          // &.mat-column-email {
          //   min-width: 360px;
          // }
        }
      }
    }
  }
}

/*------------------------------------*\
                $TICKET
\*------------------------------------*/
.ticket {
  .card__table--mobile {
    align-items: center;
    background-color: var(--bgDepth);
    border-radius: var(--buMD);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 8px 16px;
    overflow: hidden;
    padding: 16px;
    width: calc(100% - 16px);

    @include mq($from: tablet) {
      display: flex !important;
      pointer-events: initial !important;
      user-select: initial !important;
    }
  }

  .tap-wrapper {
    // margin-bottom: 16px;

    .accordion {
      background-color: var(--bgSurface) !important;
      border-radius: var(--buXS) !important;
      box-shadow: none !important;
      margin: 0 !important;
      padding: 4px 8px;

      .title-wrapper {
        margin-bottom: 0 !important;
      }

      &__title {
        background-color: transparent !important;
        border: 0 !important;
        padding: 0;
      }

      &__body {
        padding: 0;

        .tap--details {
          background-color: var(--bgSurfaceAlt);
          border-radius: var(--buSM);
          margin: 8px 0;
          padding: 8px;

          @include mq($from: tablet) {
            margin: 8px;
            padding: 8px 12px;
          }

          .card__table--mobile {
            background-color: transparent;
            border-radius: 0;
            margin: 0;
            padding: 0;

            @include mq($from: tablet) {
              display: flex !important;
              pointer-events: initial !important;
              user-select: initial !important;
            }
          }
        }
      }
    }
  }
}

/*------------------------------------*\
                $TOOLTIP
\*------------------------------------*/
.mdc-tooltip {
  &__surface {
    background-color: var(--bgSurfaceAlt) !important;
    box-shadow: var(--shadowsPrimary) !important;
    color: var(--txBrndPrimary) !important;
  }
}

// APEX CHART TOOLTIPS EDITS
.mat-mdc-tooltip-panel {
  background-color: transparent !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
  color: var(--txSecondary) !important;
}

/*------------------------------------*\
              $TESTING UI
\*------------------------------------*/
.btn {
  &.secondary {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    transition: background-color 300ms ease-in;
    width: 48px;

    &:hover {
      background-color: var(--brndPrimary200);

      svg {
        #icn-target {
          fill: var(--txInverted);
        }
      }
    }
  }
}

/*------------------------------------*\
              $SCROLLBAR
\*------------------------------------*/
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  // background: rgba(#fff, 0.16);
  border-radius: var(--buLG);
}

::-webkit-scrollbar-thumb:hover {
  // background: purple;
}

::-webkit-scrollbar-track {
  background: rgba(#161119, 0.72);
  border-radius: var(--buLG);
  box-shadow: inset 7px 10px 12px var(--sdwNeutral);
}

// PROJECT CUSTOM
.hero {
  .text--gradient {
    @include x-rem(font-size, 24px);
    @include x-rem(line-height, 28px);
    margin: 0 0 12px 0;
    text-align: left;

    @include mq($from: tablet) {
      @include x-rem(font-size, 40px);
      @include x-rem(line-height, 48px);
    }
  }
}