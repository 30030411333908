//*REM units

// This mixin enables you to use the CSS3 value `rem`, which lets you define
// property sizes based on the root element's font-size.

// author: drublic

// Note: Depending on the font-size of the root-element `rem` calculates a
// property of a current element. The fallback solution accepts a
// default-font-size`-argument which is 16px by default.

// Outputs properties that use rem with a px fallback.
// It also takes px values and converts them to rem.
//
// @param property
// @param values
// @param default-font-size int optional
// @returns
//   <property>: <parsed value>px;
//   <property>: <parsed value>rem;
//
// @example
//   .selector {
//     @include x-rem(font-size, 1.3);
//     @include x-rem(padding, 20px);
//   }
@mixin x-rem($property, $values, $main-font-size: 16px) {
  // Empty list for all values in px
  $px-values: ();
  $rem-values: ();

  // Iterate over entries
  @each $value in $values {
    // If the value is zero or of a type that doesn’t need conversion, return the value untouched
    @if index(auto inherit initial none 0, $value) or type-of($value) != 'number' {
      $px-values: append($px-values, $value);
      $rem-values: append($rem-values, $value);

      // Otherwise convert it properly
    } @else {
      $unit: unit($value);

      @if $unit == 'px' {
        $px-values: append($px-values, strip-unit($value) * 1px);
        $rem-values: append($rem-values, (calc(strip-unit($value) / strip-unit($main-font-size)) * 1rem));
      } @else {
        $px-values: append($px-values, ($value * 1px));
        $rem-values: append($rem-values, ($value / strip-unit($main-font-size)) * 1rem);
      }
    }
  }
  // Return the property and its list of converted values
  #{$property}: #{$px-values};
  #{$property}: #{$rem-values};
}
