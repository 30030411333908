* {
  box-sizing: border-box !important;
}

html,
body {
  background-color: var(--bgDepth); // To create a mobile app feeling with the content with a border radius.
  font-family: var(--tfBody);
}

$input: (
  height: 48px,
);

button:focus {
  outline: 0 !important;
}


/*------------------------------------*\
                $BADGE
\*------------------------------------*/
.mat-badge-content {
  background: var(--txWarning);
}

// .mat-badge-medium {
//   &.mat-badge-after {
//     .mat-badge-content {
//       @include x-rem(font-size, 14px);
//       right: -88px;
//     }
//   }
// }

// // badge--text
// .badge--text {
//   .mat-badge-medium {
//     &.mat-badge-after {
//       .mat-badge-content {
//         @include x-rem(font-size, 14px);
//         right: initial;
//       }
//     }
//   }
// }


/*------------------------------------*\
              $BUTTON ICONS
\*------------------------------------*/
button {

  &.primary {

    // Style navbar btn-icons on default
    svg {
      #icon__interaction-target {
        fill: var(--txBrndPrimary);
        transition: fill 300ms ease-in;
      }
    }

    // Style navbar btn-icons when active
    svg {
      &.active {
        #icon__interaction-target {
          fill: var(--txInverted);
        }

        path {
          &#icon__interaction-target--secondary {
            fill: var(--txInverted);
          }
        }
      }
    }

    @include mq($from: desktop) {
      &:hover {
        svg {
          #icon__interaction-target {
            fill: var(--txInverted);
          }
        }
      }
    }
  }

  &.secondary {

    // Style navbar btn-icons on default
    svg {
      #icon__interaction-target {
        fill: var(--txBrndPrimary);
        transition: fill 300ms ease-in;
      }
    }

    // Style navbar btn-icons when active
    &.active {
      svg {
        #icon__interaction-target {
          fill: var(--txInverted);
        }

        path {
          &#icon__interaction-target--secondary {
            fill: var(--txInverted);
          }
        }
      }
    }

    @include mq($from: desktop) {
      &:hover {
        svg {
          #icon__interaction-target {
            fill: var(--txBrndPrimary);
          }
        }
      }
    }
  }

  &.tertiary {

    // Style navbar btn-icons on default
    svg {
      #icon__interaction-target {
        fill: var(--txInverted);
      }
    }

    @include mq($from: desktop) {
      &:hover {
        svg {
          #icon__interaction-target {
            fill: var(--txInverted);
          }
        }
      }
    }
  }

  &.neutral {
    svg {
      #icon__interaction-target {
        fill: var(--txSecondary);
      }
    }

    @include mq($from: desktop) {
      &:hover {
        svg {
          #icon__interaction-target {
            fill: var(--txInverted);
          }
        }
      }
    }
  }

  &.table {
    svg {
      #icon__interaction-target {
        fill: var(--brndPrimary0500);
      }
    }

    &:hover {
      svg {
        #icon__interaction-target {
          fill: var(--txInverted);
          transition: fill 300ms ease-in;
        }
      }
    }
  }

  &.warning {
    svg {
      #icon__interaction-target {
        fill: var(--txWarning);
        transition: fill 300ms ease-in;
      }
    }

    &:hover {
      svg {
        #icon__interaction-target {
          fill: var(--txInverted);
          transition: fill 300ms ease-in;
        }
      }
    }
  }

  &.error {
    svg {
      #icon__interaction-target {
        fill: var(--txError);
        transition: fill 300ms ease-in;
      }
    }

    &:hover {
      svg {
        #icon__interaction-target {
          fill: var(--txInverted);
          transition: fill 300ms ease-in;
        }
      }
    }
  }
}

/*------------------------------------*\
            $BUTTON ICON TEXT
\*------------------------------------*/
button {
  &.btn {
    &.icon-text {

      // Style
      &.primary {
        @include x-rem(font-size, 16px);
        align-items: center;
        background-color: var(--brndPrimary0500);
        border: 0;
        border-radius: var(--buSM);
        color: var(--txInverted);
        cursor: pointer;
        display: flex;
        font-family: var(--tfBody);
        font-weight: 600;
        min-height: 48px;
        min-width: 104px;
        justify-content: center;
        padding: 12px 16px;
        transition: all 300ms ease;

        .icon-wrapper {
          height: 24px;
          margin-right: 8px;
          width: 24px;

          svg {
            #icon__interaction-target {
              fill: var(--txInverted);
            }
          }
        }

        .label {
          margin: 0;
        }

        // Actions
        &:disabled {
          background-color: var(--bgDisabled);
          color: var(--txDisabled);
          cursor: not-allowed;
          pointer-events: none;

          .icon-wrapper {
            svg {
              #icon__interaction-target {
                fill: var(--txSecondary);
              }
            }
          }
        }

        @include mq($from: desktop) {
          &:hover {
            background-color: var(--txPrimary);
          }
        }
      }

      &.secondary {
        align-items: center;
        background-color: var(--brndPrimary124);
        border: 0;
        border-radius: var(--buSM);
        cursor: pointer;
        display: flex;
        min-height: 48px;
        min-width: 104px;
        justify-content: center;
        padding: 12px 16px;
        transition: all 300ms ease;

        .icon-wrapper {
          height: 24px;
          margin-right: 8px;
          width: 24px;

          @include mq($from: desktop) {
            margin-right: 16px;
          }

          svg {
            #icon__interaction-target {
              fill: var(--brndPrimary0500);
            }
          }
        }

        .label {
          @include x-rem(font-size, 16px);
          color: var(--brndPrimary0500);
          font-family: var(--tfBody);
          font-weight: 600;
          margin: 0;
        }

        // Actions
        &:disabled {
          background-color: var(--bgDisabled);
          color: var(--txDisabled);
          cursor: not-allowed;
          pointer-events: none;

          .icon-wrapper {
            svg {
              #icon__interaction-target {
                fill: var(--txSecondary);
              }
            }
          }
        }

        @include mq($from: desktop) {
          &:hover {
            background-color: var(--brndPrimary0500);

            .icon-wrapper {
              svg {
                #icon__interaction-target {
                  fill: var(--txInverted);
                }
              }
            }

            .label {
              color: var(--txInverted);
            }
          }
        }
      }

      &.tertiary {
        align-items: center;
        background-color: transparent;
        border: 0;
        border-radius: var(--buSM);
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        min-height: 48px;
        min-width: 104px;
        padding: 12px 16px;
        transition: all 300ms ease;

        .icon-wrapper {
          height: 24px;
          margin-left: 8px;
          width: 24px;

          @include mq($from: desktop) {
            margin-left: 16px;
          }

          svg {
            #icon__interaction-target {
              fill: var(--brndPrimary0500);
            }
          }
        }

        .label {
          @include x-rem(font-size, 16px);
          color: var(--brndPrimary0500);
          font-family: var(--tfBody);
          font-weight: 600;
          margin: 0;
        }

        // Actions
        &:disabled {
          background-color: var(--bgDisabled);
          color: var(--txDisabled);
          cursor: not-allowed;
          pointer-events: none;

          .icon-wrapper {
            svg {
              #icon__interaction-target {
                fill: var(--txSecondary);
              }
            }
          }
        }

        @include mq($from: desktop) {
          &:hover {
            background-color: var(--brndPrimary0500);

            .icon-wrapper {
              svg {
                #icon__interaction-target {
                  fill: var(--txInverted);
                }
              }
            }

            .label {
              color: var(--txInverted);
            }
          }
        }
      }

      &.text {
        align-items: center;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        cursor: none;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        padding: 0;
        pointer-events: none;
        user-select: none;
        width: 100%;

        .icon-wrapper {
          height: 16px;
          margin-left: 4px;
          width: 16px;

          @include mq($from: tablet) {
            height: 24px;
            margin-left: 8px;
            width: 24px;
          }

          svg {
            #icon__interaction-target {
              fill: var(--txSecondary);
            }
          }
        }

        .label {
          @include x-rem(font-size, 14px);
          color: var(--txSecondary);
          font-weight: 300;
          margin: 0;
          text-align: right;
          overflow-x: auto;
          overflow-y: hidden;
          max-width: 15ch;
          white-space: nowrap;

          @include mq($from: mobile-new) {
            @include x-rem(font-size, 16px);
            overflow: initial;
            max-width: initial;
            text-overflow: initial;
            white-space: initial;
          }
        }
      }

      // Width
      &.full {
        width: 100%;
      }
    }

  }
}

/*------------------------------------*\
                $DIALOG
\*------------------------------------*/
// .cdk-overlay-dark-backdrop {
//   background: rgba(#091B49, .6);
// }

// .mat-dialog-container {
//   border-radius: var(--buMD) !important;
// }

// .cdk-overlay-pane {
//   &.dialog {
//     &--sm {
//       .mat-dialog-container {
//         height: 400px;
//         width: 440px;

//         .content-wrapper {
//           align-items: center;
//           display: flex;
//           flex-direction: column;
//           height: 100%;
//           justify-content: center;
//           width: 100%;
//         }

//         .feedback-icon {
//           align-items: center;
//           display: flex;
//           height: 104px;
//           justify-content: center;
//           margin-bottom: 16px;
//           width: 104px;

//           svg {
//             #icon__interaction-target {
//               height: 104px;
//               width: 104px;
//             }
//           }
//         }

//         .dialog__inner__actions {
//           justify-content: center;
//         }

//         .dialog__inner__body__content {
//           flex: initial;
//         }
//       }
//     }

//     &--md {
//       .mat-dialog-container {
//         height: 600px;
//         width: 600px;

//         .content-wrapper {
//           align-items: center;
//           display: flex;
//           flex-direction: column;
//           height: 100%;
//           justify-content: center;
//           width: 100%;
//         }

//         .feedback-icon {
//           align-items: center;
//           display: flex;
//           height: 104px;
//           justify-content: center;
//           margin-bottom: 16px;
//           width: 104px;

//           svg {
//             #icon__interaction-target {
//               height: 104px;
//               width: 104px;
//             }
//           }
//         }
//       }
//     }

//     &--lg {
//       .mat-dialog-container {
//         height: 600px;
//         width: 800px;
//         overflow: hidden !important;
//       }

//       .feedback-icon {
//         align-items: center;
//         display: flex;
//         height: 104px;
//         justify-content: center;
//         margin-bottom: 16px;
//         width: 104px;

//         svg {
//           #icon__interaction-target {
//             height: 104px;
//             width: 104px;
//           }
//         }
//       }
//     }

//     &--full {
//       .mat-dialog-container {
//         height: 100vh;
//         width: 100vw;
//         overflow: hidden !important;
//       }
//     }
//   }
// }

// .dialog {
//   &__inner {
//     height: 100%;
//     // height: calc(100% - 48px) !important;
//     padding: 24px;
//     // width: calc(100% - 48px) !important;
//     width: 100%;

//     &__header {
//       align-items: center;
//       display: flex;
//       justify-content: space-between;
//       margin-bottom: 32px;
//       width: 100%;
//     }

//     &__body {
//       align-items: center;
//       // border: 1px solid gold;
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-start;
//       overflow: auto;

//       &__content {
//         padding: 8px;
//       }
//     }

//     &__actions {
//       align-items: center;
//       display: flex;
//       justify-content: flex-end;
//       margin-top: 48px;
//       width: 100%;

//       &.center {
//         justify-content: center;
//       }

//       .btn-wrapper {
//         margin-left: 16px;
//       }
//     }
//   }
// }

/*------------------------------------*\
                $INFO BAR
\*------------------------------------*/
.info-bar {
  &.sm {
    svg {
      height: 16px;
      width: 16px;
    }
  }

  // Color
  &.error {
    background-color: var(--bgErrorOpacity);

    svg {
      #icon__interaction-target {
        fill: var(--txError);
      }
    }
  }

  &.info {
    background-color: var(--bgFrame);

    svg {
      #icon__interaction-target {
        fill: var(--txSecondary);
      }
    }
  }

  &.primary {
    background-color: var(--brndPrimary124);

    svg {
      #icon__interaction-target {
        fill: var(--brndPrimary0500);
      }
    }
  }

  &.secondary {
    background-color: var(--brndPrimary124);

    svg {
      #icon__interaction-target {
        fill: var(--brndPrimary0500);
      }
    }
  }

  &.success {
    background-color: var(--bgSuccessOpacity);

    svg {
      #icon__interaction-target {
        fill: var(--txSuccess);
      }
    }
  }

  &.warning {
    background-color: var(--bgWarningOpacity);

    svg {
      #icon__interaction-target {
        fill: var(--txWarning);
      }
    }
  }
}

/*------------------------------------*\
    $INPUT DATE --- OLD ok to delete
\*------------------------------------*/
// .input__datepicker {
//   .mdc-text-field {
//     padding: 0;
//   }

//   &.mat-mdc-form-field {
//     .mat-mdc-form-field-flex {
//       @include x-rem(font-size, 14px);
//       color: var(--txSecondary);
//       padding: 0 0 0 16px;

//       .mat-mdc-floating-label {
//         color: var(--txSecondary);
//         font-weight: 400;
//         top: 16px;

//         &.mdc-floating-label--float-above {
//           display: none;
//         }
//       }

//     }

//     .mat-mdc-form-field-required-marker {
//       display: none;
//     }
//   }
// }

// /*------------------------------------*\
//             $INPUT GENERAL
// \*------------------------------------*/
// /* Change Autocomplete styles in Chrome*/
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   -moz-appearance: none;
//   /* Firefox */
//   -webkit-appearance: none;
//   /* Safari and Chrome */
//   appearance: none;
//   background-color: var(--bgFrame) !important;
//   border: 2px solid var(--brndPrimary0500) !important;
//   box-sizing: border-box !important;
//   color: var(--txPrimary) !important;
//   font-weight: 500 !important;
//   -webkit-text-fill-color: var(--txPrimary);
//   -webkit-box-shadow: 0 0 0px 1000px var(--sdwNeutral) inset !important;
//   transition: background-color 5000s ease-in-out 0s;
// }

// .form {
//   &__input-wrapper {
//     font-family: var(--tfBody);
//     font-weight: 700;
//     display: initial;
//     position: relative;

//     &.primary {

//       // label
//       .content {
//         align-items: center;
//         background-color: transparent;
//         border-radius: var(--buSM);
//         display: flex;
//         justify-content: space-between;
//         margin: 0 0 8px;
//         padding: 0;
//         z-index: 1000;

//         p {
//           @include x-rem(font-size, 14px);
//           @include x-rem(line-height, 18px);
//           color: var(--txPrimary);
//           font-weight: 400;
//           margin: 0;
//         }
//       }

//       // input
//       input {
//         @include x-rem(font-size, 16px);
//         @include x-rem(line-height, 24px);
//         // A reset of styles
//         appearance: none;
//         background-color: var(--bgSurface);
//         border: 2px solid var(--brndPrimary0500);
//         border-radius: var(--buSM);
//         color: var(--txPrimary) !important;
//         height: map-get($input, height);
//         margin: 0 0 8px;
//         padding: 12px 48px 12px 16px;
//         width: 100%;

//         &:disabled {
//           background-color: var(--bgDisabled);
//           border: 0;
//           color: var(--txDisabled) !important;
//         }
//       }

//       ::placeholder {
//         @include x-rem(font-size, 14px);
//         @include x-rem(line-height, 24px);
//         color: var(--txDisabled);
//         font-weight: 400;
//       }

//       // error badge
//       img {

//         &.error {
//           filter: invert(1) sepia(10) saturate(16) hue-rotate(328deg);
//           height: 24px;
//           position: absolute;
//           right: 16px;
//           top: calc(50% + 8px);
//           width: 24px;
//         }

//         &.valid {
//           filter: invert(57%) sepia(76%) saturate(386%) hue-rotate(58deg) brightness(94%) contrast(82%);
//           height: 24px;
//           position: absolute;
//           right: 16px;
//           top: calc(50% + 8px);
//           width: 24px;

//           &.no-label {
//             top: calc(50% + 0px);
//           }
//         }
//       }

//       &.no-label {
//         img {

//           &.error,
//           &.valid {
//             top: calc(50% + 0px);
//           }
//         }
//       }

//       &.error {
//         input {
//           background-color: var(--bgErrorOpacity) !important;
//           border: 2px solid var(--txError);
//           color: var(--txError) !important;
//         }
//       }

//       &.success {
//         input {
//           background-color: var(--bgSuccessOpacity) !important;
//           border: 2px solid var(--txSuccess);
//           color: var(--txSuccess) !important;

//           &:-internal-autofill-selected {
//             background-color: var(--bgSuccessOpacity) !important;
//             border: 2px solid var(--txSuccess);
//             color: var(--txSuccess) !important;
//           }
//         }
//       }
//     }

//     input,
//     textarea,
//     select {
//       box-sizing: border-box !important;

//       &:focus,
//       &:focus-visible,
//       &:focus-within {
//         outline: 2px solid var(--bgFrame) !important;
//         border: 0;
//         background-color: var(--bgSurfaceAlt) !important;
//       }
//     }
//   }
// }

/*------------------------------------*\
      $INPUT RESET MATERIAL STYLES
\*------------------------------------*/
/*------------------------------------*\
            $INPUT GENERAL
\*------------------------------------*/
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-color: var(--bgFrame) !important;
  border: 2px solid var(--brndPrimary0500) !important;
  box-sizing: border-box !important;
  color: var(--txPrimary) !important;
  font-weight: 500 !important;
  -webkit-text-fill-color: var(--txPrimary);
  -webkit-box-shadow: 0 0 0px 1000px var(--sdwNeutral) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.form {
  &__input-wrapper {
    font-family: var(--tfBody);
    font-weight: 700;
    display: initial;
    position: relative;

    &.primary {

      // label
      .content {
        align-items: center;
        background-color: transparent;
        border-radius: var(--buSM);
        display: flex;
        justify-content: space-between;
        margin: 0 0 8px;
        padding: 0;
        z-index: 1000;

        p {
          @include x-rem(font-size, 14px);
          @include x-rem(line-height, 18px);
          color: var(--txPrimary);
          font-weight: 400;
          margin: 0;
        }
      }

      // input
      input {
        @include x-rem(font-size, 16px);
        @include x-rem(line-height, 24px);
        // A reset of styles
        appearance: none;
        background-color: var(--bgSurface);
        border: 2px solid var(--brndPrimary0500);
        border-radius: var(--buSM);
        color: var(--txPrimary) !important;
        height: map-get($input, height);
        margin: 0 0 8px;
        padding: 12px 48px 12px 16px;
        width: 100%;

        &:disabled {
          background-color: var(--bgDisabled);
          border: 0;
          color: var(--txDisabled) !important;
        }
      }

      ::placeholder {
        @include x-rem(font-size, 14px);
        @include x-rem(line-height, 24px);
        color: var(--txDisabled);
        font-weight: 400;
      }

      // error badge
      img {

        &.error {
          // filter: invert(1) sepia(10) saturate(16) hue-rotate(328deg);
          height: 24px;
          position: absolute;
          right: 16px;
          top: calc(50% + 8px);
          width: 24px;
        }

        &.valid {
          // filter: invert(57%) sepia(76%) saturate(386%) hue-rotate(58deg) brightness(94%) contrast(82%);
          height: 24px;
          position: absolute;
          right: 16px;
          top: calc(50% + 8px);
          width: 24px;

          &.no-label {
            top: 50%;
            // top: 25%;
          }
        }
      }

      &.no-label {
        img {

          &.error,
          &.valid {
            top: 50%;
            // top: 25%;
          }
        }
      }

      &.error {
        input {
          background-color: var(--bgErrorOpacity) !important;
          border: 2px solid var(--txError);
          color: var(--txError) !important;
        }
      }

      &.success {
        input {
          background-color: var(--bgSuccessOpacity) !important;
          border: 2px solid var(--txSuccess);
          color: var(--txSuccess) !important;
        }
      }
    }

    input,
    textarea,
    select {
      box-sizing: border-box !important;

      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: 2px solid var(--bgFrame) !important;
        border: 0;
        background-color: var(--bgSurfaceAlt) !important;
      }
    }
  }
}

.input {
  &__feedback {
    &.error {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin: 0 0 24px;
      padding: 0;
      width: 100%;

      p {
        @include x-rem(font-size, 14px);
        color: var(--txError);
        font-family: var(--tfBody);
        font-weight: 500;
        margin: 4px 0 0 0;
        padding: 0;
        width: 100%;
      }

      img {
        align-items: center;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

/*------------------------------------*\
//                $LINK
\*------------------------------------*/
a {
  &.link {
    @include mq($from: desktop) {
      &:hover {
        svg {
          #icon__interaction-target {
            fill: var(--brndPrimary0500);
          }
        }
      }
    }

    &.neutral {
      svg {
        #icon__interaction-target {
          fill: var(--txSecondary);
        }
      }
    }
  }
}

/*------------------------------------*\
//                $MENU
\*------------------------------------*/
.mat-menu-panel {

  .mat-menu-content {
    &:not(:empty) {
      padding: 0;
    }
  }

  .focus {
    background-color: var(--bgFrame);
    padding: 16px;
  }

  .inner {
    padding: 16px;
  }
}

/*------------------------------------*\
              $NAV ITEM
\*------------------------------------*/
button {
  &.nav__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    &.primary {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      width: 64px;

      @include mq($from: tablet) {
        width: 72px;
      }

      @include mq($from: desktop) {
        width: 88px;
      }

      .icon-wrapper {
        height: 24px;
        margin-bottom: 8px;
        width: 24px;

        @include mq($from: desktop) {
          height: 32px;
          width: 32px;
        }

        svg {
          #icon__interaction-target {
            fill: var(--brndPrimary140);
          }
        }
      }

      .description {
        @include x-rem(font-size, 12px);
        color: var(--brndPrimary140);
        font-family: var(--tfBody);
        font-weight: 500;
        margin: 0;
        transition: fill 300ms ease-in;

        @include mq($from: tablet) {
          @include x-rem(font-size, 14px);
          font-weight: 600;
        }

        @include mq($from: desktop) {
          @include x-rem(font-size, 16px);
        }
      }

      .active-line {
        background-color: transparent;
        border-radius: var(--buMD);
        display: initial;
        height: 4px;
        margin-top: 8px;
        width: 100%;
      }

      // Actions
      &:disabled {
        cursor: not-allowed;
        pointer-events: none;

        .description {
          color: var(--txDisabled);
        }
      }

      @include mq($from: desktop) {
        &:hover {
          svg {
            #icon__interaction-target {
              fill: var(--brndPrimary0500);
              transition: none;
            }
          }

          .description {
            color: var(--brndPrimary0500);
          }
        }
      }
    }

    // States
    &.active {
      background-color: transparent;
      border: 0;
      cursor: pointer;

      .icon-wrapper {
        svg {
          #icon__interaction-target {
            fill: var(--brndPrimary0500) !important;
            transition: none;
          }
        }
      }

      .description {
        color: var(--brndPrimary0500);
        font-family: var(--tfBody);
      }

      .active-line {
        background-color: var(--brndPrimary0500);
        border-radius: var(--buMD);
        display: initial;
        height: 4px;
        margin-top: 8px;
        width: 100%;
      }

      @include mq($from: desktop) {
        &:hover {
          svg {
            #icon__interaction-target {
              fill: var(--brndPrimary800) !important;
            }
          }

          .description {
            color: var(--brndPrimary800);
          }
        }
      }
    }

    &.margin-r-lg {
      margin: 0 16px 0 0;

      @include mq($from: desktop) {
        margin: 0 32px 0 0;
      }
    }
  }
}

/*------------------------------------*\
              $SCROLLBAR
\*------------------------------------*/
// ::-webkit-scrollbar {
//   height: 8px;
//   width: 8px;
// }

// ::-webkit-scrollbar-thumb {
//   background: var(--brndPrimary124);
//   border-radius: var(--buLG);
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: var(--brndPrimary140);
// }

// ::-webkit-scrollbar-track {
//   background: var(--bgSurface);
//   border-radius: var(--buLG);
//   box-shadow: inset 7px 10px 12px var(--sdwNeutral);
// }

/*------------------------------------*\
                  $SVG
\*------------------------------------*/
.action-wrapper {
  svg {
    #icon__interaction-target {
      fill: var(--brndPrimary0500);
    }
  }
}

.loading {

  svg path,
  svg rect {
    fill: var(--txSecondary);
  }
}

/*------------------------------------*\
                $TABLE
\*------------------------------------*/
// Code working in the app styles.scss level and not in the libs
// .mat-mdc-table {
//   display: none;
//   pointer-events: none;
//   user-select: none;

//   @include mq($from: tablet) {
//     display: block;
//     pointer-events: auto;
//     position: relative;
//     user-select: auto;
//   }

//   .mat-mdc-header-row {
//     background-color: var(--bgFrame);
//     border-radius: var(--buSM);
//     border: 0;
//     margin-bottom: 4px;

//     .mat-mdc-header-cell {
//       @include x-rem(font-size, 16px);
//       align-items: center;
//       border: 0;
//       color: var(--txSecondary);
//       display: flex;
//       font-family: var(--tfBody);
//       justify-content: center;
//       text-align: center;

//       &:first-of-type {
//         padding: 0 !important;
//       }

//       &.xl {
//         overflow: auto;
//         padding: 0 48px;
//       }

//       .mat-mdc-sort-header-arrow {
//         position: absolute;
//         right: -15px;
//       }
//     }
//   }

//   .mat-mdc-row {
//     background-color: var(--bgFrame);
//     border-radius: var(--buSM);
//     border: 0;
//     margin-bottom: 4px;

//     .mat-mdc-cell {
//       @include x-rem(font-size, 14px);
//       align-items: center;
//       border: 0;
//       color: var(--txSecondary);
//       display: flex;
//       font-family: var(--tfBody);
//       font-weight: 400;
//       justify-content: center;
//       margin: 0 8px;
//       text-align: center;

//       &:first-of-type {
//         padding: 0 !important;
//       }

//       &.mat-mdc-column-codeAlarmsList {
//         overflow: auto;

//         @include mq($from: tablet) {
//           overflow: initial;
//           max-width: 200px;
//           padding: 0;
//         }

//         @include mq($from: desktop) {
//           max-width: 280px;
//         }

//         @include mq($from: large-screen) {
//           max-width: initial;
//         }
//       }
//     }
//   }

//   table-cell-actions {
//     align-items: center;
//     display: flex;
//     justify-content: center;
//   }
// }

// .mat-mdc-table {
//   svg {
//     height: 24px;
//     width: 24px;

//     #icon__interaction-target {
//       fill: var(--txSecondary);
//     }
//   }
// }

// .card__table--mobile {
//   svg {
//     height: 24px;
//     width: 24px;

//     #icon__interaction-target {
//       fill: var(--txSecondary);
//     }
//   }
// }

// // TABLE AND WARNING ROWS
// .table-wrapper {
//   &.warning {

//     .card__table--mobile {
//       background-color: var(--bgWarningOpacity) !important;
//     }

//     .mat-mdc-table {
//       .mat-mdc-row {
//         background-color: var(--bgWarningOpacity) !important;
//       }
//     }
//   }
// }

/*------------------------------------*\
                  $TABS
\*------------------------------------*/
// OLD !!!!

// .mat-tab-group {

//   // default styles
//   .mat-tab-header {
//     background-color: var(--txInverted);

//     svg {
//       #icon__interaction-target {
//         fill: var(--txSecondary);
//       }

//       path {
//         &#icon__interaction-target {
//           fill: var(--txSecondary);
//         }
//       }
//     }

//   }

//   &.mat-primary {
//     .mat-ink-bar {
//       background-color: var(--brndPrimary0500) !important;
//     }
//   }

//   .mat-tab-label {
//     margin: 0 8px;
//     padding: 0 8px;

//     &.mat-focus-indicator {
//       background-color: var(--txInverted);
//       color: var(--txSecondary);
//       font-family: var(--tfBody);
//       font-weight: 400;
//       opacity: 1;

//       &.mat-tab-label-active {
//         background-color: var(--txInverted);

//         .mat-tab-label-content {
//           @include x-rem(font-size, 16px);
//           color: var(--brndPrimary0500);
//           font-family: var(--tfBody);
//           font-weight: 600;
//         }

//         svg {
//           #icon__interaction-target {
//             fill: var(--brndPrimary0500);
//           }

//           path {
//             &#icon__interaction-target {
//               fill: var(--brndPrimary0500);
//             }
//           }
//         }
//       }
//     }
//   

//   &.horizontal {
//     border-radius: var(--buMD);
//     padding: 8px 0 0 0;

//     .mat-tab-nav-bar,
//     .mat-tab-header {
//       border-top-left-radius: var(--buMD);
//       border-top-right-radius: var(--buMD);
//       border-bottom-left-radius: 0;
//       border-bottom-right-radius: 0;
//       border-bottom: 2px solid var(--bgFrame);
//     }
//   }
// }

// Exception for Header Tabs in Reports
// .top {
//   .mat-tab-group {
//     .mat-tab-label {
//       .mat-tab-label-content {
//         @include x-rem(font-size, 20px);
//       }

//       &.mat-focus-indicator {
//         &.mat-tab-label-active {
//           .mat-tab-label-content {
//             @include x-rem(font-size, 22px);
//           }
//         }
//       }
//     }
//   }
// }
// -------------------------------------
// NEW but partial !!!!
// .tabs-wrapper {
//   // border: 1px solid tomato;
//   display: flex;
//   justify-content: flex-end;
//   margin: 0;
//   width: 100%;

//   .mat-mdc-tab-group {

//     // default styles
//     .mat-mdc-tab-header {
//       background-color: var(--txInverted);

//       svg {
//         #icon__interaction-target {
//           fill: var(--txSecondary);
//         }

//         path {
//           &#icon__interaction-target {
//             fill: var(--txSecondary);
//           }
//         }
//       }

//     }

//     .mat-mdc-tab {
//       flex-grow: unset !important;

//       &.mdc-tab {
//         height: 100% !important;
//       }
//     }

//     .mdc-tab {
//       border-top-left-radius: var(--buMD);
//       border-top-right-radius: var(--buMD);
//       margin-left: 8px;
//       padding-top: 8px;

//       &__ripple {
//         &::before {
//           border-top-left-radius: var(--buMD);
//           border-top-right-radius: var(--buMD);
//         }
//       }

//       .mat-mdc-tab-ripple {
//         border-top-left-radius: var(--buMD);
//         border-top-right-radius: var(--buMD);
//       }

//       &__content {
//         margin-bottom: 12px;
//       }
//     }

//     .mdc-tab-indicator {
//       &__content--underline {
//         border-color: var(--brndPrimary0500);
//         border-top-width: 4px;
//         border-top-left-radius: var(--buXS);
//         border-top-right-radius: var(--buXS);
//       }
//     }


//     .mat-mdc-tab-labels {
//       justify-content: flex-end;

//       &.mat-mdc-focus-indicator {

//         &.mat-mdc-tab-label-active {
//           p {
//             &.text {
//               color: var(--brndPrimary0500);
//             }
//           }
//         }
//       }
//     }

//     .tab-wrapper {
//       align-items: center;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;

//       .icon-wrapper {
//         // border: 1px solid tomato;
//         height: 24px;
//         margin-bottom: 8px !important; //TODO: fix this ovverride libraries design for mobile first implementation
//         width: 24px;

//         svg {
//           #icon__interaction-target {
//             fill: var(--brndPrimary0500);
//           }
//         }
//       }
//     }
//   }
// }

/*------------------------------------*\
                $TOAST
\*------------------------------------*/
// Code working in the app styles.scss level and not in the libs
// .mdc-snackbar {
//   &__surface {
//     align-items: center;
//     background-color: transparent !important;
//     box-shadow: none !important;
//     display: flex !important;
//     justify-content: flex-start;
//     max-width: 100% !important;
//     padding: 0 !important;
//     width: 100%;
//   }
// }

/*------------------------------------*\
              $TOOLTIP
\*------------------------------------*/
.tooltipMain {
  @include x-rem(font-size, 14px);
  background-color: transparent !important;
  box-shadow: var(--sdwNeutral);
  color: var(--txSecondary) !important;
  font-family: var(--tfBody);
  font-weight: 500;
  padding: 8 24px;
}